<template>
  <section class="py-7 bg-blue4 map-section">
    <div class="container">
      <ImageContainer fileName="Map" background size="700px">
        <div class="caption">
          <slot>
            <h2 class="fw-semibold text-blue-grad-2 mb-4">
              {{ $lang("mapsection.title1", true) }}<br />
              {{ $lang("mapsection.title2", true) }}
            </h2>

            <p class="text-black3 mb-4">{{ $lang("mapsection.text", true) }}</p>
            <button class="btn btn-green-grad px-5" @click="goTo('contact_us')">
              {{ $lang("mapsection.button", true) }}
            </button>
          </slot>
        </div>

        <ImageContainer fileName="staff_bubble_1" class="people-blob" />
        <ImageContainer fileName="staff_bubble_2" class="people-blob" />
        <ImageContainer fileName="staff_bubble_4" class="people-blob" />
        <ImageContainer fileName="staff_bubble_3" class="people-blob" />
        <ImageContainer fileName="staff_bubble_5" class="people-blob" />
        <ImageContainer fileName="staff_bubble_6" class="people-blob" />
      </ImageContainer>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
