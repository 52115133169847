<template>
  <div class="card card-icon">
    <div class="card-header">
      <img :src="`/imgs/svg/icon_${icon}.svg`" alt="" />
    </div>
    <div class="card-body text-center">
      <p class="fw-bold text-blue2 mb-4">{{ title }}</p>
      <p>{{ body }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "girl",
    },
    title: {
      type: String,
      default: "Reception",
    },
    body: {
      type: String,
      default: "lorem ipsum dolor sit amet",
    },
  },
};
</script>

<style></style>
