<template>
  <section class="testimonial">
    <slot name="title">
      <h2 class="fw-bold text-green3 mb-5">{{ $lang("testimonialsection.title", true) }}</h2>
    </slot>

    <Swiper :modules="modules" :navigation="true" loop :breakpoints="breakpoints">
      <SwiperSlide>
        <CardTestimonial thumbnail="testimonials_1" video="cQhcYA5baRE" flag="korea" title="Bonhun Koo" />
      </SwiperSlide>
      <SwiperSlide>
        <CardTestimonial thumbnail="testimonials_2" video="hg75MA3zmmU" flag="brazil" title="Karen Bachini" />
      </SwiperSlide>
      <SwiperSlide>
        <CardTestimonial thumbnail="testimonials_3" video="kD2YqFoHyfY" flag="uruguay" title="Joaquín Piotti" />
      </SwiperSlide>
      <SwiperSlide>
        <CardTestimonial thumbnail="testimonials_4" video="Yp07SmYa7HM" flag="chile" title="Eduardo Burrows" />
      </SwiperSlide>
      <SwiperSlide>
        <CardTestimonial thumbnail="testimonials_5" video="rqAMcYmwpV0" flag="malaysia" title="Wennie Hang" />
      </SwiperSlide>
    </Swiper>


    <slot name="cta" />
  </section>
</template>

<script>
import CardTestimonial from "./CardTestimonial.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  components: {
    CardTestimonial,
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Navigation],
    breakpoints: {
      0: {
        slidesPerView: 1.25,
        spaceBetween: 10,
        centeredSlides: true,
      },
      512: {
        slidesPerView: 1.75,
        spaceBetween: 10,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: true,
      },
      992: {
        slidesPerView: 2.5,
        spaceBetween: 30,
        centeredSlides: true,
      },
    },
  }),
};
</script>

<style></style>
