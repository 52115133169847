<template>
  <section class="floatting-section" v-if="floatting">
    <div class="container">
      <div class="card card-blue4 w-shadow text-center">
        <div class="card-header pt-4 px-4">
          <h2 class="fw-bold text-blue2">
            {{ $lang("certifications.title", true) }}
          </h2>
          <p class="max-w-5 mx-auto">
            {{ $lang("certifications.text", true) }}
          </p>
        </div>
        <div class="card-body px-0">
          <Swiper
            :modules="modules"
            :pagination="true"
            loop
            :breakpoints="breakpoints"
            class="pb-5"
          >
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_asic" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_progressive" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_tie" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_lap" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_cambridge" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_ielts" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card card-white">
                <div class="card-body">
                  <ImageContainer background contain size="50px" fileName="logo_eaquals" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div class="row justify-content-center text-center mt-3">
        <div class="col-md-6 col-lg-4 mt-3">
          <router-link class="btn btn-blue-grad-1 btn-block" :to="{ name: 'accreditations' }">
            {{ $lang("certifications.cta", true) }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <div class="container mb-5">
      <slot>
        <h2 class="fw-bold text-green2">
          {{ $lang("certifications.title", true) }}
        </h2>
        <p class="max-w-6 mx-auto">{{ $lang("certifications.text", true) }}</p>
      </slot>
    </div>
    <Swiper :modules="modules" :pagination="true" loop :breakpoints="breakpoints" class="pb-5">
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_asic" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_progressive" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_tie" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_lap" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_cambridge" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_ielts" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="card card-white">
          <div class="card-body">
            <ImageContainer background contain size="50px" fileName="logo_eaquals" />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <slot name="cta" />
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  props: {
    floatting: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      0: {
        slidesPerView: 1.25,
        spaceBetween: 10,
        centeredSlides: true,
      },
      512: {
        slidesPerView: 2.8,
        spaceBetween: 10,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 3.5,
        spaceBetween: 20,
        centeredSlides: true,
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 20,
        centeredSlides: true,
      },
    },
  }),
};
</script>

<style></style>
