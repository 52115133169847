<template>
  <div class="card card-horizontal card-addresses">
    <ImageContainer :fileName="fileName" class="card-header" background> </ImageContainer>
    <div class="card-body">
      <p class="fw-bold text-blue2 m-0">{{ title }}</p>
      <p>{{ street }}</p>
      <p>{{ neighbour }}</p>
      <p>{{ neighbour2 }}</p>
      <p>{{ eircode }}</p>
      <p>{{ country }}</p>
      <p>{{ phone }}</p>
      <div class="btn btn-circle btn-green-grad">
        <i class="fa-solid fa-location-arrow"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Building 40",
    },
    fileName: {
      type: String,
      default: "campus_dublin_1",
    },
    street: {
      type: String,
      default: "Dominick Street Lower",
    },
    neighbour: {
      type: String,
      default: "Rotunda",
    },
    neighbour2: {
      type: String,
      default: "Dublin 1",
    },
    eircode: {
      type: String,
      default: "D01 P9P4",
    },
    country: {
      type: String,
      default: "Republic of Ireland",
    },
    phone: {
      type: String,
      default: "+353 (0)00000000",
    },
  },
};
</script>

<style></style>
