<template>
  <div class="card card-testimonial">
    <div class="card-header">
      <b>{{ title }}</b>
      <ImageContainer class="flag" :fileName="`flag_${flag}`" />
    </div>
    <div class="card-body">
      <VideoContainer :thumbnailFileName="thumbnail" :videoToken="video" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thumbnail: {
      type: String,
      default: "testimonials_1",
    },
    video: {
      type: String,
      default: "cQhcYA5baRE",
    },
    flag: {
      type: String,
      default: "chile",
    },
    title: {
      type: String,
      default: "Cansu Ünal",
    },
  },
};
</script>
